import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// Components
import {
  ForgotPasswordModal,
  Loading,
  Primary as SignInButton,
  Seo,
} from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'
// Hooks
import useValidateExistingUser from '../hooks/useValidateExistingUser'
import useShowError from '../hooks/useShowError'
// Styles
import {
  Container,
  Icon,
  QLogo,
  SignInTitle,
  StyledForm,
  StyledInput,
  StyledSpan,
  HorizontalLine,
  Wrapper,
} from '../styles/LoginPage.styles'
// Utils & Services
import { signUpSchema, signInSchema } from '../utils/validations'

const LoginPage = () => {
  const {
    prismicData: {
      prismicLoginPage: {
        already_have_an_account,
        apple_icon,
        confirm_password_placeholder,
        email_placeholder,
        facebook_icon,
        first_time_visiting_q_sciences,
        forget_your_password,
        google_icon,
        logo,
        password_placeholder,
        sign_in_with,
        sign_in,
        sign_up_with,
        sign_up,
        signing_in,
        title,
        to_get_started,
        use_your_email,
        welcome,
      },
      prismicHomepage: { unite_image },
    },
  } = usePrismic()

  const [isSignUp, setIsSignUp] = useState(false)

  const {
    handleFirebaseLogin,
    isAuthenticated,
    isAuthLoading,
    isEventSite,
    isVipSite,
    isVerified,
    manageReferral: { isReferral, referralData },
    firebaseLoginWithProvider,
  } = useAuthContext()

  const { cartData, isPcOfferInCart } = useCartContext()

  const [verifyAccountScreens, step] = useValidateExistingUser()
  const [error, setError, showError] = useShowError()

  useEffect(() => {
    if (isReferral || isPcOfferInCart) setIsSignUp(true)
  }, [isReferral, isPcOfferInCart])

  useEffect(() => {
    if (isAuthenticated && isVerified) {
      if (referralData?.type === 'ambassador') return navigate('/enrollment')
      if (cartData?.items.length) navigate('/cart')
      else navigate('/')
    }

    if (isAuthenticated) {
      setIsSignUp(false)
    }
  }, [isAuthenticated, isVerified, cartData, referralData])

  const handleSetResolvers = () =>
    isSignUp
      ? { resolver: yupResolver(signUpSchema) }
      : { resolver: yupResolver(signInSchema) }

  const {
    reset,
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm(handleSetResolvers())

  const onSubmit = async (data: { email: string; password: string }) => {
    const { email, password } = data
    const handleError = e => {
      setError(e)
      reset()
    }
    handleFirebaseLogin({ email, password, isSignUp, handleError })
  }

  const handleUseProvider = (provider: 'apple' | 'facebook' | 'google') => {
    firebaseLoginWithProvider(provider)
  }

  const handleSignUpSignIn = () => {
    setIsSignUp(!isSignUp)
    setError(null)
    setFocus('email')
  }

  const providerArr = [
    { name: 'apple', icon: apple_icon.url },
    // { name: 'facebook', icon: facebook_icon.url },
    { name: 'google', icon: google_icon.url },
  ]

  const showProviderLogins = () =>
    providerArr.map(({ name, icon }) => (
      <div
        key={name}
        style={{ margin: '0 1.2rem' }}
        onClick={() => handleUseProvider(name)}
        data-qa={name}
      >
        <Icon src={icon} />
      </div>
    ))

  const displayWelcome = () => {
    let welcomeText = welcome[0].text
    if (referralData?.firstname && referralData?.lastname) {
      return `${welcomeText} ${referralData?.firstname} ${referralData?.lastname}`
    }
    if (referralData?.firstname && !referralData?.lastname) {
      return `${welcomeText} ${referralData?.firstname}`
    }
    return `${welcomeText} to the Q family`
  }

  const isEventOrVip = isEventSite || isVipSite

  if (isAuthLoading)
    return (
      <Loading loading={true} message={signing_in[0].text} showLogo={true} />
    )

  return (
    <>
      <Seo title={title[0].text} />
      <Wrapper>
        {isAuthenticated && !isVerified && verifyAccountScreens[step]}
        <Container>
          <QLogo
            src={isEventOrVip ? unite_image.url : logo.url}
            width={isEventOrVip ? '500px' : '250px'}
          />
        </Container>
        {isReferral && referralData ? (
          <>
            <StyledSpan welcome>{displayWelcome()}</StyledSpan>
            <StyledSpan welcome>{to_get_started[0].text}</StyledSpan>
          </>
        ) : null}
        <Container direction="column" maxWidth="500px">
          <SignInTitle>
            {isSignUp ? sign_up_with[0].text : sign_in_with[0].text}
          </SignInTitle>
          <Container justify="center" padding="1em 0 0 0">
            {showProviderLogins()}
          </Container>
        </Container>
        <Container>
          <HorizontalLine>
            <span>{use_your_email[0].text}</span>
          </HorizontalLine>
        </Container>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          {!!error && showError(errors)}
          <Container direction="column" align="flex-start" maxWidth="500px">
            <StyledSpan error>{errors.email?.message}</StyledSpan>
            <StyledInput
              {...register('email')}
              placeholder={email_placeholder[0].text}
              onFocus={() => setError(null)}
              data-qa="emailInput"
            />
          </Container>
          <Container direction="column" align="flex-start" maxWidth="500px">
            <StyledSpan error>{errors.password?.message}</StyledSpan>
            <StyledInput
              {...register('password')}
              placeholder={password_placeholder[0].text}
              type="password"
              data-qa="passwordInput"
            />
          </Container>
          {isSignUp ? (
            <Container direction="column" align="flex-start" maxWidth="500px">
              <StyledSpan error>{errors.passwordConfirm?.message}</StyledSpan>
              <StyledInput
                {...register('passwordConfirm')}
                placeholder={confirm_password_placeholder[0].text}
                type="password"
                data-qa="passwordConfirmInput"
              />
            </Container>
          ) : null}
          <SignInButton
            type="submit"
            disabled={Object.entries(errors).length !== 0}
            data-qa="submitButton"
          >
            {isSignUp ? sign_up[0].text : sign_in[0].text}
          </SignInButton>
        </StyledForm>
        <Container
          justify="space-between"
          padding="10px 0 0 0"
          maxWidth="500px"
        >
          <span style={{ marginTop: '10px' }}>
            {forget_your_password[0].text}
          </span>
          <ForgotPasswordModal data-qa="forgotPassord" resetLogin={reset} />
        </Container>
        {isEventSite || isVipSite ? null : (
          <Container justify="space-between" padding="0" maxWidth="500px">
            <span style={{ marginTop: '10px' }}>
              {isSignUp
                ? already_have_an_account[0].text
                : first_time_visiting_q_sciences[0].text}
            </span>
            <StyledSpan
              link
              data-qa="signUpButton"
              onClick={handleSignUpSignIn}
            >
              {isSignUp ? sign_in[0].text : sign_up[0].text}
            </StyledSpan>
          </Container>
        )}
      </Wrapper>
    </>
  )
}

export default LoginPage
